.font-24 {
  font-size: 24px;
}
.icon-width {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 30px;
  color: #c0c0c0 !important;
  /* height: 56px; */
  /* padding: 15px 28px 15px 28px; */
  /* gap: 10px; */
}
.listItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 56px;
  padding-left: 12px !important;
  color: #c0c0c0 !important;

  /* padding: 16px 16px 16px 28px; */
}
.active {
    box-shadow: 5px 0px 0px 0px #3387DF inset;
}
.itemText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}
.productText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  /* line-height: 22px; */
  letter-spacing: 0px;
  /* text-align: left; */
  color:#D0D0D0;
}

.light{
  color: #616161 !important;
}
.dark{
  color: #D0D0D0 !important;
}
