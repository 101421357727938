.spinner-header {
	margin-left: 25px;
}
.spinner-svg {
	animation: rotate 2s linear infinite;
	height: 25px;
	margin-right: 4px;
	width: 25px;
}

.svg-spinner-background {
	fill: none;
	stroke: #c0c0c0;
	stroke-linecap: round;
	stroke-width: 3px;
}

.svg-spinner-spin {
	animation: dash 1.7s ease-in-out infinite;
	fill: none;
	stroke: #1976d2;
	stroke-linecap: round;
	stroke-width: 3px;
}
@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}
@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}

	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -123;
	}
}

.spinner-responsive-view {
	display: none;
}