.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* ::-webkit-scrollbar {
  width: .2em;
  height: .2em
}
::-webkit-scrollbar-button {
  background: #ccc
}
::-webkit-scrollbar-track-piece {
  background: #888
}
::-webkit-scrollbar-thumb {
  background: #eee
}​ */
*::-webkit-scrollbar {
  width: 10px !important;
}

*::-webkit-scrollbar-button {
  display: none !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #616161 !important;
  border-radius: 50px !important;
  border: 2px solid transparent !important;
  background-clip: content-box !important;
}

*::-webkit-scrollbar-track {
  background-color: transparent !important;
  border-radius: 50px !important;
}

*::-webkit-scrollbar-track-piece {
  background-color: transparent !important;
}

*::-webkit-scrollbar-corner {
  display: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
